import React, { useEffect } from 'react'
import { Container, Col, Row, Button } from 'react-bootstrap'
import Services from '../components/Services';
import * as classes from '../custom-styles/Home.module.css'
import { css } from "@emotion/react"
import ContactPage from '../components/ContactPage';
import Typography from '@mui/material/Typography';
import { PlayCircleFilled } from '@material-ui/icons'
import TransparentBackground from '../components/backgrounds/TransparentBackground';
import styled from 'styled-components';
import { device } from '../utils/breakpoints';
import { Link } from 'gatsby';
import HomeLogo from '../images/HomeLogo.svg'
import { withStyles } from "@material-ui/core/styles";

const MainContent = styled(TransparentBackground)`
    width: 100%;
    height: 600px;
    background-size: cover;
    background-repeat: no-repeat;

    @media ${device.laptop} {
        width: 100% !important;
        height: 900px !important;
        background-size: cover;
        background-repeat: no-repeat;
    }

    @media ${device.tablet} {
        height: 950px !important;
    }

    @media ${device.phone} {
        height: 480px !important;
    }
`;

const HeaderContent = styled.div`
    padding:20px 20px !important;
    margin:0;
    padding: 12px 0;
        & h1{
    font-size: 40px;
    font-weight: 550;
    margin:0;
    padding: 12px 0;
    @media ${device.tablet} {
        font-size: 40px;
        font-weight: 550;
        }
}
        & p{    
    font-size: 26px;
    margin:0;
    padding: 12px 0;
    @media ${device.tablet} {
        font-size: 18px;
        font-weight: 400;
    }
}
`;

const FreetrialButton = styled.div`
    display:flex;
    align-items:center;
    padding:12px 0;
`;

const SubheaderContent = styled.p`
    color: red;
    font-weight: bold;
    @media ${device.tablet} {
        color: red;
        font-weight: bold;
    }
`;

const TrustBorder = styled.div`
    border-bottom: 8px solid #FF5733;
    width: 100px;
    margin: 25px 0;
`;

const ApplicationsTypography = withStyles({
    root: {
      color: "#FF5733"
    }
  })(Typography);

export default function HomePage() {

    return (<>
        <MainContent>
            <Container className={`${classes.py50}`}>
                <Row>
                    <Col lg={6}>
                        <HeaderContent>
                            <Typography variant="h3" gutterBottom><strong>Take Ownership Of Your Data</strong></Typography>
                            <TrustBorder></TrustBorder>
                            <Typography variant="h4" gutterBottom>Achieve more by securely managing your business data</Typography>
                            <ApplicationsTypography variant="h5" gutterBottom><strong>Private Data Applications . Cloud and Data Migration . Software Automation</strong></ApplicationsTypography>
                            <FreetrialButton>
                                <Link to={"/platform"} className={`${classes.freetrialButton} mx-1`}>Learn How</Link>
                            </FreetrialButton>
                        </HeaderContent>
                    </Col>
                    <Col className={`${classes.backgroundimagescover}`} lg={6}>
                        <HomeLogo />
                    </Col>
                </Row>
            </Container>
        </MainContent>
        <Services />
        <ContactPage />
    </>)
}
